'use client';

import { Paragraph } from '@components/ui';
import { useSiteInfo } from '@context/siteInfoContext';
import { getStockStatusText } from '@ngg/storefront-utils';
import { useContext, useMemo } from 'react';

import { isSoonBackInStock } from '@/utils/product/isSoonBackInStock';
import { shouldShowInStockIcon } from '@/utils/product/shouldShowInStockIcon';

import { StockStatus } from '../StockStatus';
import { ProductCardContext } from './ProductCardContext';

const ProductCardStockStatus = () => {
    const { globalDictionary } = useSiteInfo();
    const { hideStock, product, locale } = useContext(ProductCardContext);
    const stockStatus = useMemo(() => {
        if (!product?.stockStatus) {
            return undefined;
        }

        const isSoonInStock = isSoonBackInStock(
            product.stockStatus,
            product.nextDeliveryDate,
        );
        const showInStockIcon = shouldShowInStockIcon(
            isSoonInStock,
            product.stockStatus,
        );
        const stockStatusText = getStockStatusText({
            stockStatus: product.stockStatus,
            nextDeliveryDate: product.nextDeliveryDate,
            dictionary: globalDictionary,
            locale,
            isShortened: true,
        });

        return {
            isSoonInStock,
            showInStockIcon,
            stockStatusText,
        };
    }, [
        product?.stockStatus,
        product?.nextDeliveryDate,
        globalDictionary,
        locale,
    ]);

    // Hide stock status if product or stock status is not available
    if (!product || !stockStatus) {
        return null;
    }

    // Hide stock status if it's hidden with hideStock prop
    if (hideStock === true) {
        return null;
    }

    return (
        <>
            <Paragraph
                className="mt-2 flex items-center gap-x-1"
                size="xxsmall"
                transform="uppercase">
                <StockStatus
                    show={!!stockStatus.showInStockIcon}
                    soonBackInStock={!!stockStatus.isSoonInStock}
                />
                {stockStatus.stockStatusText}
            </Paragraph>
        </>
    );
};

ProductCardStockStatus.displayName = 'ProductCardStockStatus';

export default ProductCardStockStatus;
